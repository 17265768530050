/* SessionsView.css */
.sessions-view {
  max-height: 300px;
  overflow-y: auto;
}

.session-list {
  display: flex;
  flex-direction: column;
}

.session-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.session-item:hover {
  background-color: #2E8BC0;
}

.session-profit {
  font-weight: bold;
  font-size: 24px;
}

.profit-positive {
  color: green;
}

.profit-negative {
  color: rgb(255, 0, 0);
}

.session-title {
  color: #B1D4E0;
  font-weight: bold;
}

.session-hourly-rate {
  color: #B1D4E0;
  font-weight: bold;
}