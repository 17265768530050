/* SettingsView.css */
.settings-view {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9998;
  }
  
  .settings-view h2 {
    color: #fff;
    margin-bottom: 20px;
  }
  
  .settings-view button {
    width: 325px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
  }
  
  .delete-all-button {
    background-color: #ff0000;
  }
  
  .settings-view button:last-child {
    background-color: #fff;
    color: #000;
  }
  
  .delete-confirmation {
    width: 325px;
    background-color: #865454;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .delete-confirmation p {
    margin-bottom: 10px;
  }
  
  .delete-confirmation-buttons {
    display: flex;
    justify-content: center;
  }
  
  .delete-yes-button {
    background-color: #ff0000;
    color: #fff;
    margin-right: 10px;
  }
  
  .delete-no-button {
    background-color: #ccc;
    color: #000;
  }

  .contact-button {
    background-color: #6c757d;
  }
  
  .contact-button:hover {
    background-color: #5a6268;
  }
  
  .website-button {
    background-color: #28a745;
  }
  
  .website-button:hover {
    background-color: #218838;
  }
  
  .twitter-button {
    background-color: #17a2b8;
  }
  
  .twitter-button:hover {
    background-color: #138496;
  }