/* Login.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.sign-in-container {
  position: relative;
}

.login-button {
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  background-color: #2E8BC0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #B1D4E0;
}

.arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  opacity: 0;
  animation: fadeInOut 2s ease-in-out infinite;
}

.arrow-top {
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px 10px 0 10px;
  border-color: #2E8BC0 transparent transparent transparent;
  animation-delay: 0s;
}

.arrow-right {
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  border-width: 10px 10px 10px 0;
  border-color: transparent #2E8BC0 transparent transparent;
  animation-delay: 0.5s;
}

.arrow-bottom {
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #2E8BC0 transparent;
  animation-delay: 1s;
}

.arrow-left {
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #2E8BC0;
  animation-delay: 1.5s;
}

.arrow::before,
.arrow::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  opacity: 0;
  animation: fadeInOut 2s ease-in-out infinite;
}

.arrow-top::before,
.arrow-top::after {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px 8px 0 8px;
  border-color: #2E8BC0 transparent transparent transparent;
}

.arrow-top::before {
  left: calc(50% - 20px);
}

.arrow-top::after {
  left: calc(50% + 20px);
}

.arrow-right::before,
.arrow-right::after {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border-width: 8px 8px 8px 0;
  border-color: transparent #2E8BC0 transparent transparent;
}

.arrow-right::before {
  top: calc(50% - 20px);
}

.arrow-right::after {
  top: calc(50% + 20px);
}

.arrow-bottom::before,
.arrow-bottom::after {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #2E8BC0 transparent;
}

.arrow-bottom::before {
  left: calc(50% - 20px);
}

.arrow-bottom::after {
  left: calc(50% + 20px);
}

.arrow-left::before,
.arrow-left::after {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent #2E8BC0;
}

.arrow-left::before {
  top: calc(50% - 20px);
}

.arrow-left::after {
  top: calc(50% + 20px);
}

.arrow::before {
  animation-delay: 0.2s;
}

.arrow::after {
  animation-delay: 0.4s;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}