/* MainView.css */
.main-view {
  width: 380px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-view-button {
  width: 400px;
  padding: 10px 20px;
  font-size: 24px;
  font-weight: bold;
  color: #B1D4E0;
  background-color: #145DA0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



.main-view-header .profit-positive,
.main-view-header .profit-negative,
.main-view-header .hourly-rate {
  font-size: 48px;
  margin-right: 10px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.main-view-header .profit-positive:hover,
.main-view-header .profit-negative:hover,
.main-view-header .hourly-rate:hover {
  color: #FFA500;
}

.main-view-button:hover {
  background-color: #2E8BC0;
}

.header-content {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-view-content {
  flex: 1;
  /* Add any additional styles for the main content */
}

.main-view-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: #0C2D48;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  flex-direction: column;
  align-items: center;
}


.main-view-header {
  position: relative;
  text-align: center;
  padding: 20px;
  background-color: #0C2D48;
  z-index: 1000;
}

.profit-positive {
  color:#2E8BC0;
  margin-right: 10px;
}

.profit-negative {
  color:#145DA0;
  margin-right: 10px;
}

.delete-all-button {
  width: 325px;
  margin-bottom: 10px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #ff0000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-all-button:hover {
  background-color: #cc0000;
}

.info-icon-wrapper {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.info-icon-wrapper:hover {
  transform: scale(1.2);
}

.info-icon {
  font-size: 24px;
  color: #B1D4E0;
  transition: color 0.3s ease;
}

.info-icon:hover {
  color: #2E8BC0;
}


.sign-out-button {
  background-color: #2E8BC0;
}

.sign-out-button:hover {
  background-color: #B1D4E0;;
}

.close-settings-button {
  background-color: #cccccc;
  color: #145DA0;
}

.close-settings-button:hover {
  background-color: #B1D4E0;;
}

.clock-in-button,
.clock-out-button {
  margin-bottom: 10px;
}

.new-session-button {
  margin-top: 10px;
}