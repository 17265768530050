/* SessionInputView.css */
.session-input-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: transparent;
}

.session-input-content {
  margin-bottom: 20px;
  z-index: 1000;
}

/* SessionInputView.css */
.session-input-field {
  width: 300px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #B1D4E0;
}

.session-input-footer {
  display: flex;
  justify-content: space-between;
  width: 325px;
  z-index: 1000;
}

.go-back-button,
.save-session-button {
  width: 150px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #B1D4E0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.go-back-button {
  background-color: #2E8BC0;
}

.go-back-button:hover {
  background-color: #145DA0;
}

.save-session-button {
  background-color: #FF7F50;
}

.save-session-button:hover {
  background-color: #145DA0;
}