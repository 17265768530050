/* IndividualSessionView.css */
.individual-session-view {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.session-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.session-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.session-label {
  font-weight: bold;
  margin-right: 10px;
}

.session-value {
  color: #B1D4E0;
  cursor: pointer;
}

.session-value input {
  width: 100px;
  padding: 5px;
  font-size: 16px;
}

.duration-input-container {
  display: flex;
  align-items: center;
}

.duration-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
}

.duration-input-wrapper input {
  width: 40px;
  padding: 4px;
  text-align: center;
}

.duration-label {
  font-size: 12px;
  margin-top: 4px;
}

.duration-separator {
  margin: 0 4px;
}

.session-details-buttons {
  display: flex;
  justify-content: space-between;
  width: 325px;
  margin-bottom: 20px;
}

.delete-button,
.save-button {
  width: 150px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #B1D4E0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button {
  background-color: #145DA0;
}

.delete-button:hover {
  background-color: #cc0000;
}

.save-button {
  background-color: #2E8BC0;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #1c6d9e;
}

.save-button.saved {
  background-color: #4caf50;
}

.back-button {
  width: 325px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #B1D4E0;
  background-color: #2E8BC0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #1c6d9e;
}